nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 5px;
  height: 68px;
  background-color: #14032A;
}

nav p {
  margin:0;
}

.banner-image {
  display:relative;
  height: 45px;
  margin-top:6px;
  margin-left:5px;
}

nav ul {
  list-style-type:none;
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  padding:0;
  margin:0;
}