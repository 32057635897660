.activity {
  color:black;
  background: #ffffff;
  border:2px solid black;
  margin: 3px;
  padding:3px;
  display:flex;
}

.label {
  font-weight:bold;
}

.activity a {
  color:#0B5897;
}

.activity a:hover {
  color:#4E0E9F;
}

.activity ul {
  list-style-type:none;
  padding:0;
  text-align:left;
}

.activity li {
  padding:5px;
}

.thumbnail {
  max-height:100%;
  max-width:50%;
  flex: 1;
  object-fit:cover;
  margin:5px;
}

@media screen and (max-width: 500px) {
  .activity {
    flex-direction:column;
    align-items:center;
  }
}