.activities ul {
  padding:0;
  margin:0;
}

.add-activity a {
  color:#0B5897;
}

.add-activity a:hover {
  color:#4E0E9F;
}
